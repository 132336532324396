import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import Fade from "react-reveal/Fade";
import Footer from "../App";
import { Link } from "react-router-dom";
import AdultIntakePdf from "../assets/AdultIntake.pdf";
import CouplesIntakePdf from "../assets/CouplesIntake.pdf";
import CreditCardAuthorization from "../assets/CreditCardAutorization.pdf";
import InformedConsent from "../assets/InformedConsent.pdf";
import NoticeOfPrivacyPractices from "../assets/NoticeofPrivacyPractices.pdf";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { faUserFriends } from "@fortawesome/free-solid-svg-icons";
import { faCreditCard } from "@fortawesome/free-solid-svg-icons";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { faAddressCard } from "@fortawesome/free-solid-svg-icons";

import "../AppCss.css";

const opts = { width: "100%" };

class FormsAndNotices extends React.Component {
  render() {
    return (
      <Container>
        <Fade left>
          <Row style={{ minHeight: "5vh", width: "100%" }}></Row>

          <Row
            className="container"
            style={{
              minHeight: "20vh",
              width: "100%",
              backgroundColor: "lightgray",
              padding: "5%",
              marginLeft: "0px",
              marginRight: "0px",
              background: "rgba(255,255,255,0.9)"
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "100%",
                height: "100%"
              }}
              className="mx-auto text-center"
            >
              <h1
                className="display-4"
                style={{
                  color: "black",
                  margin: "8px",
                  paddingBottom: "20px",
                  textAlign: "left"
                }}
              >
                Forms and Notices
              </h1>
              <Row>
                <Col className="Aligner" style={{ paddingBottom: "20px" }}>
                  <div>
                    <div>
                      <FontAwesomeIcon
                        icon={faUser}
                        style={{ fontSize: "50px" }}
                      />
                    </div>
                    <Link to={AdultIntakePdf} target="_blank" download>
                      Adult Intake Form
                    </Link>
                  </div>
                </Col>
                <Col className="Aligner" style={{ paddingBottom: "20px" }}>
                  <div>
                    <div>
                      <FontAwesomeIcon
                        icon={faUserFriends}
                        style={{ fontSize: "50px" }}
                      />
                    </div>
                    <Link to={CouplesIntakePdf} target="_blank" download>
                      Couples Intake Form
                    </Link>
                  </div>
                </Col>
                <Col className="Aligner" style={{ paddingBottom: "20px" }}>
                  <div>
                    <div>
                      <FontAwesomeIcon
                        icon={faCreditCard}
                        style={{ fontSize: "50px" }}
                      />
                    </div>
                    <Link to={CreditCardAuthorization} target="_blank" download>
                      Credit Card Authorization
                    </Link>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className="Aligner" style={{ paddingBottom: "20px" }}>
                  <div>
                    <div>
                      <FontAwesomeIcon
                        icon={faLock}
                        style={{ fontSize: "50px" }}
                      />
                    </div>
                    <Link to={InformedConsent} target="_blank" download>
                      Informed Consent
                    </Link>
                  </div>
                </Col>
                <Col className="Aligner" style={{ paddingBottom: "20px" }}>
                  <div>
                    <div>
                      <FontAwesomeIcon
                        icon={faAddressCard}
                        style={{ fontSize: "50px" }}
                      />
                    </div>
                    <Link
                      to={NoticeOfPrivacyPractices}
                      target="_blank"
                      download
                    >
                      Notice of Privacy Practices
                    </Link>
                  </div>
                </Col>
                <Col></Col>
              </Row>
            </div>
          </Row>

          <Row style={{ minHeight: "5vh", width: "100%" }}></Row>
        </Fade>
      </Container>
    );
  }
}

export default FormsAndNotices;
