import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import Fade from "react-reveal/Fade";
import { Collapse } from "react-collapse";
import YouTube from "react-youtube";

const opts = { width: "100%" };

class Specialties extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.toggleEmdrShowMore = this.toggleEmdrShowMore.bind(this);
    this.toggleEmdrIntensives = this.toggleEmdrIntensives.bind(this);
    this.toggleIndShowMore = this.toggleIndShowMore.bind(this);

    this.state = {
      emdrShowMore: false,
      intensivesShowMore: false,
      indShowMore: false,

      emdrButtonContent: "Learn more about EMDR Therapy",
      intensivesButtonContent: "Learn more about EMDR Intensives",
      indButtonContent: "Learn more about Individual Counseling"
    };
  }

  _onReady(event) {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  }

  toggleEmdrShowMore() {
    if (this.state.emdrShowMore) {
      this.setState({
        emdrShowMore: false,
        emdrButtonContent: "Learn more about EMDR Therapy"
      });
    } else {
      this.setState({
        emdrShowMore: true,
        emdrButtonContent: "Click to Close"
      });
    }
  }

  toggleEmdrIntensives() {
    if (this.state.intensivesShowMore) {
      this.setState({
        intensivesShowMore: false,
        intensivesButtonContent: "Learn more about EMDR Intensives"
      });
    } else {
      this.setState({
        intensivesShowMore: true,
        intensivesButtonContent: "Click to Close"
      });
    }
  }

  toggleIndShowMore() {
    if (this.state.indShowMore) {
      this.setState({
        indShowMore: false,
        indButtonContent: "Learn more about Individual Counseling"
      });
    } else {
      this.setState({
        indShowMore: true,
        indButtonContent: "Click to Close"
      });
    }
  }

  render() {
    return (
      <Container>
        <Row>
          <Col lg>
            <Fade left>
              <Row style={{ minHeight: "5vh", width: "100%" }}></Row>

              <Row
                className="container"
                style={{
                  minHeight: "20vh",
                  width: "100%",
                  backgroundColor: "lightgray",
                  padding: "5%",
                  marginLeft: "0px",
                  marginRight: "0px",
                  background: "rgba(255,255,255,0.9)",
                  fontSize: "18"
                }}
              >
                <h1 className="display-4" style={{ color: "black" }}>
                  Counseling for Individuals
                </h1>
                <p className="lead">
                  Reaching out for help is courageous, and I’d like to help you
                  utilize that courage to move forward gaining peace and
                  fulfillment.
                </p>
                <Button
                  onClick={this.toggleIndShowMore}
                  style={{ marginBottom: "15px" }}
                >
                  {" "}
                  {this.state.indButtonContent}{" "}
                </Button>
                <Collapse
                  isOpened={this.state.indShowMore}
                  style={{ maxWidth: "95%" }}
                >
                  <p style={{ wordWrap: "break-word" }}>
                    Do any of these sound familiar?
                  </p>
                  <ul>
                    <li>
                      Other people see you as a high-achiever, but your internal
                      voice is uncertain or critical, and you’d like to connect
                      to your confidence.
                    </li>
                    <li>
                      You’d like to restore your sense of purpose and move past
                      the uncomfortable sense that life is running you while you
                      struggle to keep up.
                    </li>
                    <li>
                      Your coping skills are ready for a tune-up and you’d like
                      help to find what works for you now.
                    </li>
                    <li>
                      You want to feel more connection and satisfaction within
                      the relationships in your life.
                    </li>
                    <li>
                      You are seeking a safe space to explore your experiences
                      and find ways to get past the things holding you back from
                      living fully.{" "}
                    </li>
                  </ul>

                  <p style={{ wordWrap: "break-word" }}>
                    Maybe you’ve been in the habit of saying “I’m fine”, because
                    you didn’t feel like you deserve or should need any help,
                    although you recognize that we all encounter circumstances
                    that are challenging to face alone.
                  </p>
                  <p style={{ wordWrap: "break-word" }}>
                    Reaching out for help is courageous, and I’d like to help
                    you utilize that courage to move forward toward gaining
                    peace and fulfillment.
                  </p>
                  <p style={{ wordWrap: "break-word" }}>
                    Healing starts by finding what isn’t working, and breathing
                    into it with compassion, without judgment. This exploration
                    typically includes discovery of your resilience – the tools
                    and skills you may not be aware you already have. We’ll then
                    work together to help you resolve thoughts and habits that
                    are no longer serving you well and add new tools to help you
                    thrive so you can find peace and the freedom to create the
                    life that you desire and deserve.{" "}
                  </p>
                  <p style={{ wordWrap: "break-word" }}>
                    My approach involves using evidence-based practices tailored
                    to your needs to help you explore and clarify your emotional
                    experience, restore your relationship with yourself,
                    strengthen your relationships with partners, friends, and
                    family, and reconnect you to your passions and purpose.{" "}
                  </p>
                  <p style={{ wordWrap: "break-word" }}>
                    I look forward to helping you discover your best life. Let’s
                    work together and get you feeling better!{" "}
                  </p>
                  <p className="lead">
                    Contact me to schedule your free, 15-minute phone
                    consultation.{" "}
                  </p>
                  <p className="lead" style={{ wordWrap: "break-word" }}>
                    Email me at jenniferLMFT@jenniferrowetherapy.com or call
                    949-345-1981
                  </p>
                </Collapse>
              </Row>
            </Fade>

            <Fade right>
              <Row style={{ minHeight: "5vh", width: "100%" }}></Row>

              <Row
                className="container"
                style={{
                  minHeight: "20vh",
                  width: "100%",
                  backgroundColor: "lightyellow",
                  padding: "5%",
                  marginLeft: "0px",
                  marginRight: "0px",
                  background: "rgba(255,255,255,0.9)",
                  display: "block"
                }}
              >
                <h1 className="display-4" style={{ color: "black" }}>
                  What are EMDR Intensives?
                </h1>
                <p className="lead"></p>
                <Button onClick={this.toggleEmdrIntensives}>
                  {" "}
                  {this.state.intensivesButtonContent}{" "}
                </Button>
                <Collapse
                  isOpened={this.state.intensivesShowMore}
                  style={{ maxWidth: "95%" }}
                >
                  <p>
                    <h3 style={{ paddingTop: "15px"}}>
                      <b>
                        How it Works:
                      </b>
                    </h3>
                    <ol>
                      <li>During the intake appointment we will discuss goals for the intensive sessions, narrow down our focus for the EMDR and if time allows begin with resourcing exercises. Together, we will decide how many EMDR intensive sessions to schedule for the current phase of short term psychotherapy. This will range from two or three half day EMDR intensive sessions depending on your needs.
                      </li>
                      <li>During the EMDR intensive sessions we begin with resourcing exercises, continue identifying our target memories that cause you emotional disturbance, and begin reprocessing and clearing out the distress associated with the target memories.
                      </li>
                      <li>The follow up session is scheduled a few weeks after we finish the EMDR intensive sessions. During this session we will check in with how life has been after completing EMDR and re-evaluate for recommendations for the next steps. This may be continue therapy with your primary therapist, continued EMDR to focus on other memories, or continue living your life free from trauma.
                      </li>
                    </ol>
                    <h3>
                      <b>
                        How EMDR Intensive Programs are Different from Traditional Therapy:
                      </b>
                    </h3>
                    <p>
                      EMDR Intensive Programs are NOT intended to be a long term solution to therapy. They are intended to get you in, bring you relief in DAYS and get you moving forward in your journey to be your best self.  Think of it more as “Change” therapy and not on-going “Support” therapy.
                    </p>
                    <p>
                      The traditional weekly therapy model is 53-60 minute sessions on a recurring basis usually for months or years at a time. Yes, EMDR works in the traditional model, but it is stunted by the short amount of time that is available. As soon as you start to get into the stuff that needs to be healed, it’s time to shut it down again and wait until next week. This often brings very incremental doses of relief, that over time results in progress, but often feels discouraging.
                    </p>
                    <p>
                      Routine weekly therapy session $200/session for 6 months = $4,800 and for 1 year that’s a total of $9,600.
                    </p>
                    It’s hard to compare ongoing sessions with EMDR intensive programs, and results are going to vary for every person. After doing hundreds of EMDR sessions in the 1-hour format, I can confidently state that we make more progress in 2 day intensive sessions than we would in 3 separate 1 hour sessions. And, what I have heard over and over again from real clients who have done the work in the traditional model vs. the intensive model is that they feel better leaving session and are able to get through more of the healing that they needed to faster.
                  </p>
                </Collapse>
              </Row>
            </Fade>

            <Fade left>
              <Row
                style={{
                  minHeight: "5vh",
                  width: "100%",
                  marginLeft: "0px",
                  marginRight: "0px"
                }}
              ></Row>

              <Row
                className="container"
                style={{
                  minHeight: "20vh",
                  width: "100%",
                  backgroundColor: "lightblue",
                  padding: "5%",
                  marginLeft: "0px",
                  marginRight: "0px",
                  background: "rgba(255,255,255,0.9)"
                }}
              >
                <h1 className="display-4" style={{ color: "black" }}>
                  What is EMDR therapy?
                </h1>
                <p className="lead">
                  EMDR (Eye Movement and Desensitization and Reprocessing) is a
                  psychotherapy treatment that enables individuals to heal from
                  the symptoms and emotional distress from traumatic or
                  difficult life experiences. <span style={{ fontWeight: 'bold' }}>I am EMDR certified.</span>
                </p>
                <Button
                  onClick={this.toggleEmdrShowMore}
                  style={{ marginBottom: "15px" }}
                >
                  {" "}
                  {this.state.emdrButtonContent}{" "}
                </Button>
                <Collapse
                  isOpened={this.state.emdrShowMore}
                  style={{ maxWidth: "100%" }}
                >
                  <div style={{ width: "100%" }}>
                    <YouTube
                      videoId="Pkfln-ZtWeY"
                      opts={opts}
                      onReady={this._onReady}
                    />
                  </div>
                  <p style={{ wordWrap: "break-word" }}>
                    EMDR (Eye Movement and Desensitization and Reprocessing) is
                    a psychotherapy treatment that enables individuals to heal
                    from the symptoms and emotional distress from traumatic or
                    difficult life experiences. Numerous controlled studies have
                    shown EMDR to be an effective form of psychotherapy with an
                    increased rate in reduction of symptoms than traditional
                    talk therapy alone. It recognized by the American
                    Psychiatric Association, the American Psychological
                    Association, the Department of Defense and the Department of
                    Veterans Affairs as an efficacious treatment for PTSD. What
                    can I expect during an EMDR session?
                  </p>
                  <p style={{ wordWrap: "break-word" }}>
                    During EMDR sessions, I will ask you to focus on a
                    disturbing memory, emotion or body sensation, while applying
                    gentle, bilateral stimulation (visual) which repeatedly
                    activates the right and left side of the brain. Bilateral
                    stimulation assists the neurophysiological system to release
                    emotional experiences that get stuck in the nervous system
                    and returns the body to equilibrium. Thus, it accelerates
                    your brains capacity to process and heal the memory being
                    targeted, which can leave you feeling a greater sense of
                    ease and self-agency. It can be very effective for those
                    troubling memories or ingrained patterns of behavior or
                    negative thoughts. My clients often express they feel a
                    sense of “freedom” from the event being processed with
                    reduced emotional distress, new insights and a sense of
                    their agency to handle and overcome the distressing
                    situation. Please visit www.emdr.com for additional
                    information.{" "}
                  </p>
                  <h2>What issues are helped by EMDR?</h2>
                  <p style={{ wordWrap: "break-word" }}>
                    Studies to date show a high degree of effectiveness with the
                    following issues:
                  </p>

                  <Row style={{ width: "100%" }}>
                    <Col>
                      <ul>
                        <li>Grief and loss</li>
                        <li>Posttraumatic Stress Disorder</li>
                        <li>Traumatic events</li>
                        <li>Anxiety or panic</li>
                        <li>Self-blame, shame or guilt</li>
                        <li>Stuck patterns of behaviors and/or reactions</li>
                        <li>Phobias</li>
                        <li>Performance anxiety Depression</li>
                      </ul>
                    </Col>
                    <Col>
                      <ul>
                        <li>Work stress</li>
                        <li>Sense of impending doom</li>
                        <li>Low self esteem</li>
                        <li>Sense of powerlessness</li>
                        <li>Relationship problems</li>
                        <li>Difficulty trusting others</li>
                        <li>
                          Habitual negative beliefs about yourself and others
                        </li>
                        <li>Hypervigilance</li>
                      </ul>
                    </Col>
                  </Row>

                  <p className="lead">
                    Contact me for your free 15-minute phone consultation to see
                    how EMDR may help you gain freedom from your symptoms.
                  </p>
                  <p className="lead" style={{ wordWrap: "break-word" }}>
                    Email me at jenniferLMFT@jenniferrowetherapy.com or call
                    949-345-1981
                  </p>
                </Collapse>
              </Row>
            </Fade>
          </Col>
        </Row>
        <Row style={{ minHeight: "5vh", width: "100%" }}></Row>
      </Container>
    );
  }
}

export default Specialties;
