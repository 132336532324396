import React from "react";
import { Navbar, Nav, Container,Row,Col } from "react-bootstrap";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import Fade from 'react-reveal/Fade';
import './AppCss.css';
import Specialties from './components/Specialties';
import FormsAndNotices from "./components/FormsAndNotices";
import JenniferRoweCircle from "./assets/jenniferrowecircle.png";
import JRT_Photo from "./assets/JRT_Photo.jpeg"
import emdria from "./assets/emdria.png";

require('typeface-crimson-text');

function App() {
  return (
    <Router>
      
  <div className="Background_Style" style={{fontFamily: "'Crimson Text', serif"}}>
        <Header />
        <Route exact path="/" component={Home} />
        <Route path="/specialties" component={SpecialtiesFcn} />
        <Route path="/contactMe" component={Inquiry} />
        <Footer />
      </div>
    </Router>
  );
}

function SpecialtiesFcn(){
  return(
    <Specialties></Specialties>
  )
}

function Inquiry() {
  return(
    <Container className="container">

    <Fade duration="1800" right>
      <Row style={{marginLeft:'0px',marginRight:'0px',width:'100%'}}>          
          
          <Row style={{minHeight:'5vh',width:'100%',marginLeft:'0px',marginRight:'0px'}}>

          </Row>

          

          <Row style={{minHeight: '65vh',width:'100%',marginLeft:'0px',marginRight:'0px',padding:'5%',backgroundColor: 'white',background: 'rgba(255,255,255,0.8)'}}>
            <div style={{display: 'flex', flexDirection:'column',justifyContent: 'center',padding:'5%',width:'100%',height: '100%'}} className="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
              <h1 className="display-4" style={{color:'black'}}>Contact Me</h1>
              <p className="lead">I’m available some weeknights and some Saturdays upon request.</p>
              <p className="lead">Email me at jenniferLMFT@jenniferrowetherapy.com or call 949-345-1981</p>
              <p className="lead">LMFT #77408</p>
              <p className="lead">EMDR Certified</p>
            </div>

          </Row>
                  
        
        </Row>

        <Row style={{minHeight:'5vh',width:'100%',marginLeft:'0px',marginRight:'0px'}}></Row>

      </Fade>

      {/* <FormsAndNotices /> */}

      <Row style={{marginLeft:'0px',marginRight:'0px',width:'100%'}}>
        
    </Row>
    </Container>
  )
}

function Footer() {
  return(
    <footer style={{color:'black',backgroundColor:'lightblue',padding:'5%',textAlign:'center',height:'100%'}} className="">
    <p className="lead">Now offering EMDR Intensives.</p>
    <p className="lead">Email me at jenniferLMFT@JenniferRoweTherapy.com or call 949-345-1981</p>
    <hr className="featurette-divider"></hr>
    <Row>
      <Col md={4} className="Aligner">
        <img className="Aligner-item" alt="" style={{height: '60px',width:'300px',maxWidth:'25vh',maxHeight:'25vh'}} src="https://s3.amazonaws.com/www.jenniferrowetherapy.com/psyc_today_png.png"></img>
      </Col>
      <Col md={4} className="Aligner">
        <img className="Aligner-item" alt="" style={{height: '100px',width:'100px',maxWidth:'25vh',maxHeight:'25vh'}} src={emdria}></img>
      </Col>
      <Col md={4} className="Aligner">
        <img className="Aligner-item" alt="" style={{height: '100px',width:'100px',maxWidth:'25vh',maxHeight:'25vh'}} src="https://s3-us-west-2.amazonaws.com/pubgoer-pics/CAMFT-Logo.png"></img>
      </Col>
    </Row>
    <hr className="featurette-divider"></hr>
    <Row>
      <Col md={6}>
          <p className="lead">22362 Gilberto. #208</p>
          <p className="lead">RSM, CA. 92688</p>
          <br></br>
      </Col>
      <Col md={6}>
          <p className="lead">Positive Psychology Wellness Lab</p>
          <p className="lead">1854 South Coast Highway</p>
          <p className="lead">#1</p>
          <p className="lead">Laguna Beach, CA 92651</p>
          <br></br>
      </Col>
    </Row>
    
  </footer>
  );
}

function Home() {
  return(
    <div style={{fontFamily: "'Crimson Text', serif"}}>
      <Container className="container">

      <Fade duration="1800" right>
      <Row style={{marginLeft:'0px',marginRight:'0px',width:'100%'}}>          
          
          <Row style={{minHeight:'5vh',width:'100%',marginLeft:'0px',marginRight:'0px'}}>

          </Row>

          

          <Row style={{minHeight: '85vh',width:'100%',marginLeft:'0px',marginRight:'0px',padding:'5%',backgroundColor: 'white',background: 'rgba(255,255,255,0.8)'}}>
            <div style={{display: 'flex', flexDirection:'column',justifyContent: 'center',padding:'5%',width:'100%',height: '100%'}} className="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center Aligner">
              
              
           
              <img className="Aligner-item" alt="" style={{maxHeight: '320px', width: 'auto', borderRadius: '50%'}} src={JRT_Photo}></img>

              <h1 className="display-4" style={{color:'black'}}><strong>Jennifer Rowe Therapy</strong></h1>
              <p className="lead">Offices located in Laguna Beach and RSM.</p>
              <p className="lead">Licensed Marriage & Family Therapist</p>
              <p className="lead">Certified EMDR Therapist</p>
              <p className="lead">LMFT #77408</p>
              <p className="lead">EMDR Therapy: Where healing becomes a journey, and wounds transform into wisdom, unlocking the path to a brighter, resilient and more empowered you.</p>
            </div>

          </Row>
                  
        
      </Row>
      </Fade>  
   
        <Row style={{marginLeft:'0px',marginRight:'0px'}}>          
          
            <Fade duration="1800" right>

            <Row style={{minHeight:'5vh',width:'100%',marginLeft:'0px',marginRight:'0px'}}>

            </Row>

            <Row style={{width:'100%',backgroundColor:'lightyellow',marginLeft:'0px',marginRight:'0px',padding:'5%',height: '100%',background: 'rgba(240,230,140,0.85)'}}>
              <div style={{display: 'flex', flexDirection:'column',justifyContent: 'center',padding:'5%'}} className="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
                <h1 className="display-4" style={{color:'black'}}>You’re one step closer to positive change</h1>
                <p className="lead">Just by choosing to come here, tells me you’re on a search for emotional health. Possibly for a happier relationship, or a search to let go of the pain from the past, or just to find some peace. Life has its ups and downs and I’m here to help you navigate them. It can feel overwhelming at times but therapy can help! If you are struggling or just need some guidance, I am here for you. I’m non-judgmental, compassionate and love being a therapist! I provide a safe, nurturing environment where we can work together to find solutions. Your emotional health and well-being is my goal! I can’t wait to hear all about you!</p>
              </div>

            </Row>
            </Fade>          
          
        </Row>

        <Specialties />
        {/* <FormsAndNotices /> */}
        
      </Container>
    </div>
    
  );
}

function Header() {
  return (
    <Navbar sticky="top" bg="dark" variant="dark" expand="lg">
    <Navbar.Brand href="/">Jennifer Rowe Therapy</Navbar.Brand>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav>
        <Nav.Link href="/specialties">Specialties</Nav.Link>
        <Nav.Link href="/contactMe">Contact Me</Nav.Link>
        <Nav.Link rel="noopener noreferrer" target="_blank" href="https://www.psychologytoday.com/us/therapists/jennifer-d-rowe-laguna-hills-ca/453070">Psychology Today Verified</Nav.Link>
      </Nav>
    </Navbar.Collapse>
  </Navbar>
  );
}

export default App;
